.logo {
  margin: 10px 0;
  max-width: 350px;
  width: 100%;
}

.site-header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}